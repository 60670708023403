<template>
   <div
      class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--white-online">
      <div class="ph-inpage-widget__content">
        <div class="ph-inpage-widget__row ph-inpage-widget__row_center">
          <div class="ph-inpage-widget__thumb ph-inpage-widget__thumb-big ph-inpage-widget__thumb-rounded-100">
            <img :src="require('@/assets/panel/images/inpage/empty.svg')" v-show="!imgIcon" alt="" />
            <img :src="imgIcon" v-show="imgIcon" />
            <div class="ph-inpage-widget__online"></div>
          </div>
          <div class="ph-inpage-widget__txt">
            <div class="ph-inpage-widget__title ph-inpage-widget__txt-overflow-1">
              {{ adTitle }}
            </div>
            <div class="ph-inpage-widget__desc ph-inpage-widget__txt-overflow-2">
              {{ adText }}
            </div>
          </div>
        </div>
        <div class="ph-inpage-widget__close">&#10005;</div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>